// import DeviceDetector from "device-detector-js";

export default {
  data() {
    return {
      is_wechat: false,
    }
  },
  mounted() {
    // https://www.jianshu.com/p/e710901a0c6c
    var ua = navigator.userAgent.toLowerCase()
    this.is_wechat = ua.match(/MicroMessenger/i) == 'micromessenger'
    // const deviceDetector = new DeviceDetector();
    // const userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
    // const device = deviceDetector.parse(userAgent);

    // console.log(this.is_wechat);
  },
  destroyed() {
  },
}
