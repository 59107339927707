export const payType = {
  0: '銀聯轉賬',
  1: '支付寶轉賬',
  2: 'USDT轉賬',
}

export const smsPeriod = 120

export const defaultTitle = '元亨 - 线上百家乐第一品牌'

export const iosSyphonDownloadUrl =
  'https://apps.apple.com/us/app/syphon/id1496285352'

export const androidSyphonDownloadUrl =
  'https://github.com/syphon-org/syphon/releases/download/0.2.13/syphon.android.apk'
