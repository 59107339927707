<template>
  <div class="home">
    <page-header />
    <div class="banner" data-aos="fade" data-aos-delay="200"></div>
    <div class="notice flex-ac" data-aos="fade" data-aos-delay="200">
      <img alt="" class="notice-icon" src="../assets/images/notice.png" />
      <div class="notice-msg">
        <marquee
          align="middle"
          behavior="scroll"
          direction="left"
          loop="-1"
          width="100%"
          v-text="notice_msg"
        ></marquee>
      </div>
    </div>
    <Navigator v-if="!$store.state.isMobileLayout" />
    <Entrys :games="games" ref="search"/>
    <suggest-browser></suggest-browser>
    <slogan></slogan>

    <a-modal
      v-model="showResponseModal"
      @ok="$store.commit('setShowResponse', false)"
    >
      <template slot="title">
        <div class="modal-title">免责声明</div>
      </template>
      <div class="modal-content">
        1.本網站所提供的遊戲,只限法律允許地區及符合法定年齡的個人使用。<br />
        2.為避免於本網站投注時之爭議，請會員務必在遊戲同意前，詳細閱讀本娛樂城所定之各項規則，客戶一經『同意』進入本遊戲進行投注時，即被視為已接受所有規定。<br />
        3.客戶有責任確保自己的帳戶及登入資料的保密性，以會員帳號及密碼進行的任何網上投注，將被視為“有效”。請您於首次使用前更改原始密碼。如果客戶發現或懷疑自己的資料被盜用，請告知本公司或代理商，立即做密碼變更之操作。<br />
        4.開牌後，若您已有下注，請確認您的輸贏金額。<br />
        5.您的”總下注金額”及”贏得金額”亦會每局顯示於右上邊，請會員詳加確認（”贏得金額”按含本金方式顯示）。<br />
        6.當會員在遊戲中途發生網路問題而斷線，所有已被確定的投注仍然有效。會員再重新登入時，就可以查詢遊戲端內的“下注紀錄”查詢發牌結果，會員的額度也會隨著當局的輸贏增加或減少。<br />
        7.本網上遊戲上在現場把牌將牌例顯示在會員端屏幕上。<br />
        8.在洗牌或將牌放入牌靴過程中，有牌不慎曝光，荷官會把牌迭起並重新洗牌，牌局將重新開始。<br />
        9.當發牌時不小心同時在牌靴抽出兩張牌,在沒翻開情況下,按正常次序發牌,牌局如常繼續。<br />
        10.派錯牌例（已不須補牌，荷官仍補牌）多補的那張牌將退回下局，牌局將如常進行；若該張多補的牌已亮開，由公司決定是否取消。<br />
        11.荷官未依閑、莊發牌正確順序將牌放錯位置，由現場人員將牌依正確順序擺放回位置後牌局將照常繼續。若牌局已開配，而結果不符，系統將根據視訊荷官完成該局之正確結果，牌局也將在牌路無誤的情況下如常繼續。<br />
        12.發牌視訊僅保留三日，若有異議請於遊戲當日起三日內提出，三日後恕不受理。<br />
        13.本娛樂城之視訊為真人直播，故該局遊戲若因國際線路傳輸問題出現爭議，將以視訊看到牌局結果決定輸贏。<br />
        14.本娛樂城所提供之牌路僅供參考，若因國際線路問題或其他因素造成牌路顯示有誤，所有遊戲結果將以现场視频開牌为准。<br />
        15.本公司保留一切有爭議事項的修正及最後的決策。<br />
        16.本娛樂城保留隨時更改、修訂或刪除遊戲、遊戲規則（包括機率及賠率）及協議條款的權利而無須作事先通知。<br />
        17.本娛樂城保留隨時修訂、撤銷或中止任何投注的權利而無須作事先通知，亦無須作任何解釋。<br />
        18.會員在本娛樂城之遊戲廳內任何遊戲的押注紀錄均視為有效，會員需自行承擔下注後的風險。<br />
        19.以上内容，本公司保留最終解釋權。<br />
      </div>
      <template slot="footer" class="modal-footer">
        <a-checkbox :checked="true">我已阅读并同意以上条款</a-checkbox>
        <br />
        <a-button
          key="submit"
          type="primary"
          @click="$store.commit('setShowResponse', false)"
        >
          确认承诺
        </a-button>
      </template>
    </a-modal>

    <a-modal
      v-model="showTransferModal"
      @ok="$store.commit('setTransferModal', false)"
    >
      <template slot="title">
        <div class="modal-title">額度轉換</div>
      </template>
      <div class="modal-content">
        <div style="text-align:left;">
          <p class="enter-title">請選擇轉出帳號 : {{outputAccount}}</p>
          <p class="enter-title">請選擇轉入帳號 : {{inputAccount}}</p>
          <p class="enter-title">請輸入轉換金額 : <input type="text" v-model="inputValue" style="background: none;opacity:1;" v-on:input="checkInputValue" :disabled="loading" ></p>
          <p class="enter-title" v-bind:style="{ color: outputColor }">[{{outputAccount}}]持有金額 : {{money}}</p>
          <p class="enter-title" v-bind:style="{ color: inputColor }">[{{inputAccount}}]轉換後金額 : {{inputMoney}}</p>
        </div>
      </div>
      <template slot="footer" class="modal-footer">
        <a-button
          key="submit"
          type="primary"
          @click="$store.commit('setTransferModal', false)"
          :disabled="loading"
        >
          取消
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="confirm()"
          :disabled="loading"
        >
          進入遊戲
        </a-button>
      </template>
    </a-modal>
    <page-footer />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/page-header.vue'
import PageFooter from '@/components/page-footer.vue'
import Navigator from '@/components/Navigator.vue'
import Entrys from '@/components/Entrys.vue'
import SuggestBrowser from '@/components/suggest-browser.vue'
import Slogan from '@/components/slogan.vue'
import { mapState } from 'vuex'
import { get } from '@/axios'
import { post } from '@/axios'
import jsonp from 'jsonp'
import usr from '@/mixins/usr'

export default {
  name: 'home',
  mixins: [usr],
  data() {
    return {
      notice_msg: '',
      outputAccount: '',
      inputAccount: '',
      inputValue: 0,
      money: 0, 
      totalMoney: 0,
      inputMoney: 0,
      afterMoney: 0,
      gameInfo: '',
      outputColor: '',
      inputColor: '',
      loading: false,
      api_key: 'fe01ac104d4af02857839d1d62b5202d31bdaedc689928f533ff031e'
    }
  },
  components: {
    PageHeader,
    PageFooter,
    Navigator,
    Entrys,
    Slogan,
    SuggestBrowser
  },
  mounted() {
    this.getCarouselBanner()
    this.getGameList()
    this.getIpJson()
    this.getUserInfo()
  },
  computed: {
    ...mapState(['showResponseModal', 'showTransferModal'])
  },
  methods: {
    getIpJson() {
      jsonp(
        `https://api.ipdata.co/?api-key=${this.api_key}&callback=ip_json`,
        null,
        (err, data) => {
          if (err) {
            console.error(err.message)
          } else {
            if (data) {
              this.$store.commit('setIpJson', data)
            }
          }
        }
      )
    },
    openTransfer(game){
        this.outputColor = ''
        this.inputColor = ''
        this.outputAccount = "現金網"
        this.inputAccount = ""
        this.totalMoney = this.userInfo.totalBalance
        this.money = this.totalMoney
        this.gameInfo = game
        if(this.userInfo.power_switch !== '1'){
            this.redirecGame()
            return
        }
        for (let i = 0; i < this.$store.state.gamemoney.length; i++) {
            var item = this.$store.state.gamemoney[i]
            if(game.game_divide == item.game_divide){
                this.inputAccount = item.game_name
                this.afterMoney = item.surBalance
                this.gameId = game.game_id
                if(this.afterMoney === "--"){
                    this.afterMoney = 0
                }
                this.inputMoney = this.afterMoney
                break
            }
        }
        
        this.$store.commit('setTransferModal', true)
    },
    checkInputValue() {
      if(this.inputValue.length === 0) {
        this.inputMonegetIpJsony = '請輸入數字[' + this.afterMoney + ']'
        this.inputColor = 'red'
        this.money = this.totalMoney
      } else if(!isNaN(this.inputValue)) {
        if(this.inputValue < 0){
            this.inputMoney = '請輸入正確數字[' + this.afterMoney + ']'
            this.money = this.totalMoney
            this.inputColor = 'red'
        } else {
            this.inputMoney = parseInt(this.afterMoney) + parseInt(this.inputValue)
            this.money = this.totalMoney - this.inputValue
            if(this.inputMoney < 0 ){
              this.inputColor = 'red'
            } else {
              this.inputColor = 'green'
            }
            if(this.money < 0 ){
              this.outputColor = 'red'
            } else {
              this.outputColor = 'green'
            }
        }
      } else {
        this.money = this.totalMoney
        this.inputMoney = '您輸入的不是數字[' + this.afterMoney + ']'
        this.inputColor = 'red'
      }
    },
    async confirm(){
        if(this.userInfo.power_switch !== '1'){
            this.redirecGame()
            return
        }

        if(this.inputValue <= 0){
            this.redirecGame()
            return
        }

        if(this.inputColor == 'red' || this.outputColor == 'red') {
            this.outputColor = 'red'
            return
        }
        var checkType = 1
        var gamename = ''
        var gameId = ''
        if("現金網" == this.outputAccount){
            checkType = 0
            gamename = this.inputAccount
        }
        if("現金網" == this.inputAccount){
          checkType = 1
          gamename = this.outputAccount
        }
    
        for (let i = 0; i < this.$store.state.gamemoney.length; i++) {
          var item = this.$store.state.gamemoney[i]
          if(gamename == item.game_name){
            gameId = item.game_id
            break
          }
        }

        const mapStr = JSON.stringify({
          game_id: gameId,
          balance: this.inputValue,
          checkType: checkType,
          status: checkType
        })
    
        console.log(mapStr)
        try {
          this.loading = true
          const { code, info, data } = await post(
            'quota_addQuotaConversion.do',
            this.Qs.stringify({ mapStr })
          )
          let res = JSON.parse(data)
          if(res != null && res['flag'] == 0){
            alert('額度轉換成功')
          } else {
            alert('額度轉換失敗，請稍後再試')
          }
        } catch(error){
            console.log(error.message)
            alert('額度轉換異常，請稍後再試')
        } finally {
          this.getUserInfo()
          this.loading = false
          this.redirecGame()
        }
    },
    redirecGame(){
        this.$store.commit('setTransferModal', false)
        this.$refs.search.enterGame(this.gameInfo)
    },
    getCarouselBanner() {
      get('gamecenter_getCarouselBanner.do').then(res => {
        if (res.code === 'fail') {
          this.$store.dispatch('logOut')
        } else {
          const data = JSON.parse(res.data)
          const { bannerList } = data
          const notice = bannerList[0]
          if (notice) {
            const { notice_title, notice_content } = notice
            if (notice_title && notice_content) {
              this.notice_msg = `【${notice_title}】 ${notice_content}`
            } else {
              this.notice_msg = ''
            }
          } else {
            this.notice_msg = ''
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.home {
  background-image: url('../assets/images/login-bg-1.png');
  background-size: 100% 100%;
}

.banner {
  width: 100vw;
  height: size(600);
  background-image: url('../assets/images/banner-2.png');
  background-size: cover;
}

.notice {
  width: 100vw;
  height: size(60);
  background-color: black;
  padding: 0 size(20);
}

.notice-icon {
  width: size(30);
  margin-right: size(10);
}

.notice-msg {
  width: 100%;
  color: white;
  font-size: size(24);
}

.modal-title {
  font-size: 1rem;
  text-align: center;
}

.modal-content {
  font-size: 0.7rem;
  color: black;
  line-height: 1.8;
  height: 10rem;
  overflow-y: scroll;
}

.modal-footer {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.ant-checkbox-wrapper {
  width: 100%;
  margin-bottom: 1rem;
}

@include md {
  .home {
    background-image: url('../assets/images/login-bg-1.png');
    background-size: 100% 100%;
  }

  .banner {
    width: 100vw;
    height: sizem(215);
    background-image: url('../assets/images/banner-2-m.png');
    background-position: center;
    background-size: cover;
  }

  .notice {
    width: 100vw;
    height: sizem(35);
    background-color: black;
    padding: 0 sizem(10);
  }

  .notice-icon {
    width: sizem(25);
    margin-right: sizem(10);
  }

  .notice-msg {
    color: white;
    font-size: size-m(12);
    white-space: nowrap;
  }

  .modal-content {
    height: 7rem;
  }
}
</style>
