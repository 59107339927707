export default {
  data() {
    return {
      detecor: null,
      show_translate: false,
    }
  },
  mounted() {
    const s1 = document.createElement('script')
    s1.type = 'text/javascript'
    s1.defer = true
    s1.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    document.body.appendChild(s1)

    const s2 = document.createElement('script')
    s2.type = 'text/javascript'
    s2.async = true
    s2.innerHTML = `function googleTranslateElementInit() {
      if (!google) {
        window.hasTranslate = false
      } else {
        window.hasTranslate = true
        new google.translate.TranslateElement(
          { pageLanguage: 'zh-CN', autoDisplay: false },
          'app'
        );
      }
        
      }`
    document.body.appendChild(s2)

    const s3 = document.createElement('script')
    s3.type = 'text/javascript'
    s3.defer = true
    s3.src = 'https://cdn.jsdelivr.net/gh/lewis-kori/vue-google-translate@main/src/utils/translatorRegex.js'
    document.body.appendChild(s3)

    clearInterval(this.detecor)
    this.detecor = setInterval(() => {
      this.show_translate = this.window.hasTranslate
      if (this.show_translate) {
        clearInterval(this.detecor)
      }
    }, 2000)
  },
  destroyed() {
    clearInterval(this.detecor)
  },
}
