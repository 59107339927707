import axios from 'axios'
// import router from '@/router'
// import app from '@/main'

const DEV_PATH = 'http://localhost:8080/api'
// const PRD_PATH = 'https://dev.s16688.xyz/api'
const baseURL =
  process.env.NODE_ENV === 'development'
    ? DEV_PATH
    : window.location.origin + '/api'

const instance = axios.create({
  baseURL,
  timeout: 100000,
  withCredentials: true,
  headers: {
    post: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  },
})

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是200的情况
  async error => {
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          // 拿 access_token
          // if (error.response.config.url === '/token') {
          //   // 連 refresh_token 也過期，跳轉登入頁面
          //   store.commit('user/setInfo', { key: 'token', value: '' })
          //   app.$message({
          //     message: '請登入帳號',
          //     type: 'warning',
          //   })
          //   router.replace({
          //     path: '/login',
          //     query: { redirect: router.currentRoute.fullPath },
          //   })
          // } else {
          //   post('/token', { refresh_token: store.state.user.refresh_token }).then(res => {
          //     if (res.token) {
          //       store.commit('user/setInfo', { key: 'token', value: res.token })
          //       store.dispatch('user/getUserInfo')
          //       window.location.reload()
          //     }
          //   })
          // }
          break
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        // case 403:
        //   //   Toast({
        //   //     message: '登录过期，请重新登录',
        //   //     duration: 1000,
        //   //     forbidClick: true,
        //   //   })
        //   // 清除token
        //   localStorage.removeItem('token')
        //   store.commit('loginSuccess', null)
        //   // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
        //   setTimeout(() => {
        //     router.replace({
        //       path: '/login',
        //       query: {
        //         redirect: router.currentRoute.fullPath,
        //       },
        //     })
        //   }, 1000)
        //   break
        // 404请求不存在
        case 404:
          // router.replace({
          //   path: '/404',
          // })
          break
        // 其他错误，直接抛出错误提示
        default:
        //   Toast({
        //     message: error.response.data.message,
        //     duration: 1500,
        //     forbidClick: true,
        //   })
      }
      return Promise.reject(error.response)
    }
  },
)

export const get = async (url, params) => {
  const { data: rtnData } = await instance.get(url, { params })
  return rtnData
}

export const post = async (url, params) => {
  const { data: rtnData } = await instance.post(url, params)
  return rtnData
}

/**
 * delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function remove(url, params) {
  // store.commit('setLoading', true)
  // app.$Progress.start()
  return new Promise((resolve, reject) => {
    instance
      .delete(url, params)
      .then(res => {
        // store.commit('setLoading', false)
        // app.$Progress.finish()
        resolve(res.data)
      })
      .catch(err => {
        // store.commit('setLoading', false)
        // app.$Progress.fail()
        reject(err.data)
      })
  })
}
