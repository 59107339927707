<template>
  <div
    :class="`entry flex-ac ${$store.state.isMobileLayout ? 'wrap' : 'wrap'}`"
  >
    <div
      v-for="game in gameList"
      :key="game.game_id"
      class="entry-card"
      @click="openTransfer(game)"
    >
      <img
        :src="window.location.origin + '/api' + game.game_logo"
        alt=""
        class="entry-bg"
      />
      <div class="entry-mask flex-c wrap">
        <div class="entry-title">{{ game.game_name }}</div>
        <div class="entry-desc">{{ game.game_remarks }}</div>
      </div>
    </div>
    <!-- <div class="entry-card">
      <img :src="img_list[index]" alt="" class="entry-bg">
      <div class="entry-mask flex-c wrap">
        <div class="entry-title">{{gameList[1].game_name}}</div>
        <div class="entry-desc">{{gameList[1].game_remarks}}</div>
      </div>
    </div>
    <div class="entry-card">
      <img :src="img_list[index]" alt="" class="entry-bg">
      <div class="entry-mask flex-c wrap">
        <div class="entry-title">{{gameList[1].game_name}}</div>
        <div class="entry-desc">{{gameList[1].game_remarks}}</div>
      </div>
    </div> -->
    <div id="jd_div" style="display:none;position:absolute;top: -100px;left: -300px;background: #343445;" >
        <textarea id="jd_key" style="" rows="1" contentEditable=true ></textarea>
    </div>
  </div>
</template>

<script>
import { post } from '@/axios'
import jsonp from 'jsonp'
// const api_url = 'https://yh2288.net/'
export default {
  name: 'entry',
  props: ['games'],
  data() {
    return {
      img_list: [
        require('../assets/images/poker-entry.png'),
        require('../assets/images/live-entry.png'),
        require('../assets/images/ag-entry.png'),
        require('../assets/images/fish-entry.png'),
        require('../assets/images/slot-entry1.png'),
        require('../assets/images/slot-entry2.png'),
        require('../assets/images/poker-entry.png'),
        require('../assets/images/football-entry.png')
      ],
      window,
      api_key: 'fe01ac104d4af02857839d1d62b5202d31bdaedc689928f533ff031e'
    }
  },
  computed: {
    gameList() {
      return this.games.map(game => game.gameList).flat()
    }
  },
  methods: {
    openTransfer(game){
      this.$parent.openTransfer(game)  
    },
    enterGame(game) {
      if (game.game_divide == 4) {
        const mapStr = JSON.stringify({
          game_id: game.game_id,
          game_divide: game.game_divide,
          game_type: game.game_type,
          checkType: game.checkType,
          ip_json: this.$store.state.ip_json
        })
        this.$message.loading('游戏读取中')
        post('gamecenter_getGameToken.do', this.Qs.stringify({ mapStr })).then(
          res => {
            if (res.code == 'fail') {
              this.$message.error(res.info, 2)
            } else {
              const data = JSON.parse(res.data)
              const {
                customerurl,
                game_divide,
                flag,
                backurl,
                loginFlag,
                // game_type,
                version,
                targetusername,
                token,
                gameUrL,
                url,
                msg,
                info
              } = data
              console.log(data, data.url)
              if (flag == '0') {
                if (game.game_divide == '0' || game.game_divide == '1') {
                  const enterUrl = `${gameUrL}/Home/MemberToward?targetusername=${targetusername}&key=${token}&version=${version}&customerurl=${customerurl}&backurl=${backurl}`
                  // https://g3.s16880.club/Home/MemberToward?targetusername=starabc001&key=7B9728E3F55125BDBCEB4C96825A8586&version=1&customerurl=https%3A%2F%2Fkf888.club&backurl=https%3A%2F%2Fbaobo777.net%2FgameCenter_page.do
                  window.location.href = enterUrl
                } else if (game.game_divide == '2') {
                  if (loginFlag == '0') {
                    window.location.href = url
                  } else {
                    this.$router.push('/error') // outline
                  }
                } else if (game.game_divide == '3') {
                  window.location.href = url
                } else if (game.game_divide == '4') {
                  window.location.href = url
                } else if (game.game_divide == '5') {
                  window.location.href = url
                } else if (game.game_divide == '6' || game.game_divide == '8') {
                  if(data.jd_key != "null"){
                    if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {//區分iPhone裝置
                        window.getSelection().removeAllRanges();//這段程式碼必須放在前面否則無效
                        var Url2=document.getElementById("jd_key");//要複製文字的節點
                        document.getElementById("jd_div").style.display="block";
                        var range = document.createRange();
                        // 選中需要複製的節點
                        range.selectNode(Url2);
                        // 執行選中元素
                        window.getSelection().addRange(range);
                        // 執行 copy 操作
                        var successful = document.execCommand('copy');
     
                        // 移除選中的元素
                        window.getSelection().removeAllRanges();
                    }else{
                        var Url2=document.getElementById("jd_key");//要複製文字的節點
                        Url2.select(); // 選擇物件
                        document.execCommand("Copy"); // 執行瀏覽器複製命令
                    }  
                    this.$message.loading('密碼已複製...');
                    window.location.href = data.gameUrL
                  } else {
                    this.$message.error('未設定密碼，請洽服務人員！')
                  }
                } else {
                  this.$message.error('進入遊戲異常，請稍後再試...', 2)
                }
              } else {
                this.$message.error(msg, 2)
              }
              // if (gameUrL) {
              //   const enterUrl = `${gameUrL}/Home/MemberToward?targetusername=${targetusername}&key=${token}&version=${version}&customerurl=${customerurl}&backurl=${backurl}`
              //   // https://g3.s16880.club/Home/MemberToward?targetusername=starabc001&key=7B9728E3F55125BDBCEB4C96825A8586&version=1&customerurl=https%3A%2F%2Fkf888.club&backurl=https%3A%2F%2Fbaobo777.net%2FgameCenter_page.do
              //   window.location.href = enterUrl
              // } else if (url) {
              //   // window.location.href = 'https://baobo777.net/gameRrrorPage.do'
              //   this.$router.push('/error')
              //   // downApp('https://getoutline.org/get-started/#step-3')
              // }
            }
          }
        )
      } else {
        jsonp(
          `https://api.ipdata.co/?api-key=fe01ac104d4af02857839d1d62b5202d31bdaedc689928f533ff031e&callback=ip_json`,
          null,
          (err, data) => {
            if (err) {
              console.error(err.message)
            } else {
              if (data) {
                this.$store.commit('setIpJson', data)
                const mapStr = JSON.stringify({
                  game_id: game.game_id,
                  game_divide: game.game_divide,
                  game_type: game.game_type,
                  checkType: game.checkType,
                  ip_json: this.$store.state.ip_json
                })
                this.$message.loading('游戏读取中')

                post(
                  'gamecenter_getGameToken.do',
                  this.Qs.stringify({ mapStr })
                ).then(res => {
                  if (res.code == 'fail') {
                    this.$message.error(res.info, 2)
                  } else {
                    const data = JSON.parse(res.data)
                    const {
                      customerurl,
                      game_divide,
                      flag,
                      backurl,
                      loginFlag,
                      // game_type,
                      version,
                      targetusername,
                      token,
                      gameUrL,
                      url,
                      msg,
                      info
                    } = data
                    console.log(data, data.url)
                    console.log('-url: ', url)
                    if (flag == '0' && (url || ((game.game_divide == '6' || game.game_divide == '8') && data.gameUrL))) {
                      if (game.game_divide == '0' || game.game_divide == '1') {
                        const enterUrl = `${gameUrL}/Home/MemberToward?targetusername=${targetusername}&key=${token}&version=${version}&customerurl=${customerurl}&backurl=${backurl}`
                        // https://g3.s16880.club/Home/MemberToward?targetusername=starabc001&key=7B9728E3F55125BDBCEB4C96825A8586&version=1&customerurl=https%3A%2F%2Fkf888.club&backurl=https%3A%2F%2Fbaobo777.net%2FgameCenter_page.do
                        window.location.href = enterUrl
                      } else if (game.game_divide == '2') {
                        if (loginFlag == '0') {
                          window.location.href = url
                        } else {
                          this.$router.push('/error') // outline
                        }
                      } else if (game.game_divide == '3') {
                        window.location.href = url
                      } else if (game.game_divide == '4') {
                        window.location.href = url
                      } else if (game.game_divide == '5') {
                        window.location.href = url
                      } else if (game.game_divide == '7') {
                        window.location = url
                      } else if (game.game_divide == '6' || game.game_divide == '8') {
                          if(data.jd_key && data.jd_key != "null"){
                            document.getElementById("jd_div").style.display="block";
                            document.getElementById("jd_key").value = data.jd_key;
                            if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {//區分iPhone裝置
                                this.$router.push('/ios/' + data.jd_key + '/' + encodeURIComponent(data.gameUrL));
                            }else{
                                var Url2=document.getElementById("jd_key");//要複製文字的節點
                                Url2.select(); // 選擇物件
                                document.execCommand("Copy"); 
                                this.$message.loading('密碼已複製...');
                                window.location.href = data.gameUrL; // 執行瀏覽器複製命令
                            }  
                          } else {
                            this.$message.error('未設定密碼，請洽服務人員！')
                          }
                      } else {
                        this.$message.error('進入遊戲異常，請稍後再試...', 2)
                      }
                    } else {
                        this.$message.error('進入遊戲異常，請稍後再試...', 2)
                    }
                    // if (gameUrL) {
                    //   const enterUrl = `${gameUrL}/Home/MemberToward?targetusername=${targetusername}&key=${token}&version=${version}&customerurl=${customerurl}&backurl=${backurl}`
                    //   // https://g3.s16880.club/Home/MemberToward?targetusername=starabc001&key=7B9728E3F55125BDBCEB4C96825A8586&version=1&customerurl=https%3A%2F%2Fkf888.club&backurl=https%3A%2F%2Fbaobo777.net%2FgameCenter_page.do
                    //   window.location.href = enterUrl
                    // } else if (url) {
                    //   // window.location.href = 'https://baobo777.net/gameRrrorPage.do'
                    //   this.$router.push('/error')
                    //   // downApp('https://getoutline.org/get-started/#step-3')
                    // }
                  }
                })
              }
            }
          }
        )
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.entry {
  width: 100vw;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  padding: size(40);
  background-color: #343445;
  position: relative;
}

.entry-card {
  width: size(280);
  height: size(370);
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 3px 0 #000;
  cursor: pointer;
  margin-bottom: 2rem;
  margin-right: 1rem;
}

.entry-bg {
  width: 126%;
  position: absolute;
  top: -13%;
  left: -13%;
}

.entry-mask {
  width: 102%;
  height: size(170);
  background-image: url('../assets/images/entry-hover-bg.png');
  background-size: 100% 100%;
  position: absolute;
  left: -1%;
  bottom: 0;
  align-content: center;
}

.entry-title {
  width: 100%;
  font-size: size(28);
  line-height: 1;
  color: white;
  text-align: center;
  margin-top: size(40);
  margin-bottom: size(20);
}

.entry-desc {
  width: 100%;
  font-size: size(18);
  line-height: 1;
  color: #eee;
  text-align: center;
}

@include md {
  .entry {
    width: 100vw;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    padding: sizem(20);
    background-color: #343445;
    position: relative;
  }

  .entry-card {
    width: 47%;
    height: sizem(205);
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 3px 0 #000;
    margin: 0 1.5% sizem(25);
  }
  .entry-bg {
    width: 126%;
    position: absolute;
    top: -35%;
    left: -13%;
  }
  .entry-mask {
    width: 102%;
    height: sizem(95);
    background-image: url('../assets/images/entry-hover-bg.png');
    background-size: 100% 100%;
    position: absolute;
    left: -1%;
    bottom: 0;
    align-content: center;
  }
  .entry-title {
    width: 100%;
    font-size: size-m(16);
    line-height: 1;
    color: white;
    text-align: center;
    margin-top: sizem(20);
    margin-bottom: sizem(10);
  }
  .entry-desc {
    width: 100%;
    font-size: size-m(12);
    line-height: 1;
    color: #eee;
    text-align: center;
  }
}
</style>
