<template>
  <div id="navigatorlayout">
    <div class="main">
      <router-view></router-view>
    </div>
    <Navigator v-if="$store.state.isMobileLayout" />
    <fixed-menu></fixed-menu>
  </div>
</template>

<script>
// @ is an alias to /src
import Navigator from '@/components/Navigator.vue'
import FixedMenu from '@/components/fixed-menu.vue'

export default {
  name: 'navigatorlayout',
  data() {
    return {}
  },
  components: {
    Navigator,
    FixedMenu,
  },
  mounted() {
    // console.log(this.$cookies.get('memberid'), "this.$cookies.get('memberid')")
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

@include md {
  .main {
    margin-bottom: sizem(39);
  }
}
</style>
