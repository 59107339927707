export const isMobile = () =>
  window.navigator.userAgent.match(/iPhone/i) !== null ||
  window.navigator.userAgent.match(/Android/i) !== null

export const isTablet = () => window.navigator.userAgent.match(/iPad/i) != null

export const isPC = () => !isMobile()

export const getCookie = name => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}

export const scrollToTop = () => {
  try {
    window.scrollTo(0, 0)
    document.body.scrollTop = 0
  } catch {
  }
}

export const getSiteName = (agent = null) => {
  if (typeof window === 'undefined') return ''

  if (
    agent !== null &&
    (window.location.origin.startsWith('dev') ||
      window.location.origin.startsWith('localhost'))
  ) {
    switch (agent) {
      case 'yh3388':
        return 'yuanheng'
      case '9d888':
        return 'jiuding'
      case 'yuehao298':
        return 'yuehao'
      case 'wm8888':
        return 'wanmei'
      case '630138138':
        return 'kakawan'
      default:
        return ''
    }
  }

  const host = window.location.origin // window.location.host

  // if (['dev.s16688.xyz', 'yh3388.vip', 'yh3388.club'].indexOf(host) > -1) {
  //   return 'yuan-heng'
  // }

  if (host.indexOf('s16688') > -1 || host.indexOf('yh3388') > -1) {
    return 'yuanheng'
  }

  if (host.indexOf('9d888') > -1) {
    return 'jiuding'
  }

  if (host.indexOf('yuehao298') > -1) {
    return 'yuehao'
  }

  if (host.indexOf('wm8888') > -1) {
    return 'wanmei'
  }

  if (host.indexOf('630138138') > -1) {
    return 'kakawan'
  }

  return 'yuanheng'
}

export const getLogo = name => {
  return require('@/assets/images/logo/' + name + '.png')
}

export const getLogoLogin = name => {
  return require('@/assets/images/logo-login/' + name + '.png')
}
