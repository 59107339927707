<template>
  <div :class="['fixed-menu', { 'is-mobile': $store.state.isMobileLayout }]">
    <div class="menu-items">
      <a :href="customerServiceUrl" class="customer-btn item" target="_blank">
      </a>
      <router-link
        class="syphon-btn item"
        target="_blank"
        to="/tutorial/syphon"
      >
      </router-link>
      <router-link class="tutorial-btn item" target="_blank" to="/tutorial/vpn">
        <p>VPN</p>
        <p>教程</p>
      </router-link>
      <router-link
        class="tutorial-btn item"
        target="_blank"
        to="/tutorial/register"
      >
        <p>注册</p>
        <p>教学</p>
      </router-link>
      <div
        v-if="$route.name !== 'member_center'"
        class="logout-btn item flex-c"
        @click="$store.dispatch('logOut')"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fixed-menu',
  computed: {
    customerServiceUrl() {
      return `https://kf888.club/?username=${this.$store.state.username}`
    },
    syphonGuideUrl() {
      return 'https://docs.google.com/document/d/e/2PACX-1vTU-E24pTEEQtFN9b1Q2dFkQmh8ddiam82xHMS02B9TOuAY0umQ5DSkpEz8uRujBgfFYWBnEAkSDRrF/pub'
    },
    vpnGuideUrl() {
      return 'https://docs.google.com/document/u/1/d/e/2PACX-1vTQkoA_sigtGk0ERDBwN_VlGFCg7A9d9H9fiWOxnd2Cjl4fK46-aMpLQQKNxRedSwN0OEUGzMb87Fih/pub'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';
@import '~@/assets/style/variables.scss';

.fixed-menu {
  position: fixed;
  top: 15%;
  right: 32px;
  z-index: 99;

  .menu-items {
    display: flex;
    flex-direction: column;

    .item {
      width: 80px;
      height: 80px;
      cursor: pointer;
      margin-bottom: 20px;
      font-size: 16px;

      &.customer-btn {
        background: center/contain no-repeat url('../assets/images/customer-service.png');
      }

      &.syphon-btn {
        background: center/contain no-repeat url('../assets/images/syphon.png');
        height: 100px;
        margin-bottom: 10px;
      }

      &.tutorial-btn {
        background: #333;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        line-height: 1.5;
        letter-spacing: 2px;
      }

      &.logout-btn {
        background: center/contain no-repeat url('../assets/images/logout-btn.png');
      }
    }
  }
}

@include md {
  .fixed-menu {
    top: 10%;
    right: 0;

    > .menu-items {
      > .item {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        font-size: 10px;

        &.syphon-btn {
          height: 60px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
