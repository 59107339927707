import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['userInfo', 'games']),
  },
  methods: {
    ...mapActions(['getUserInfo', 'getGameList']),
  },
}
