<template>
  <div v-if="!$store.state.isMobileLayout" class="suggest flex-c">
    <div class="suggest-content flex-ab">
      <span>建议使用 Google Chrome 浏览器以获得最佳游戏体验</span>
      <img alt="" class="google" src="@/assets/images/google.png" />
      <img alt="" class="chrome" src="@/assets/images/chrome.png" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'suggest-browser',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.suggest {
  width: 100vw;
  height: size(100);
  background-color: #1c1e25;
  color: white;
  font-size: size(20);
  position: relative;

  .suggest-content {
    > span {
      line-height: 30px;
    }
  }

  .google {
    width: 120px;
    margin-left: 20px;
  }

  .chrome {
    width: 30px;
    margin-left: 10px;
  }
}
</style>
