import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import NavigatorLayout from '../components/NavigatorLayout.vue'
import { defaultTitle } from '@/const'
import _ from 'lodash'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: NavigatorLayout,
    children: [
      {
        path: '',
        component: Home,
      },
      // other main page admin
    ],
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/forget_pwd',
    name: 'ForgetPwd',
    component: () => import('../views/ForgetPwd.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/error.vue'),
  },
  {
    path: '/ios/:key/:url',
    name: 'ios',
    component: () => import('../views/ios.vue'),
  },
  {
    meta: {
      name: 'APP 下载',
    },
    path: '/download_app',
    name: 'download_app',
    component: () => import('../views/download_app.vue'),
  },
  {
    meta: {
      name: '联络我们',
    },
    path: '/contact_us',
    name: 'contact_us',
    component: () => import('../views/contact_us.vue'),
  },
  {
    path: '/member_center',
    name: 'member_center',
    component: () => import('../views/member_center.vue'),
    children: [
      {
        meta: {
          name: '银行卡',
        },
        path: 'bank-card',
        component: () =>
          import('../views/member_center/bank-card/bank-card.vue'),
        children: [
          {
            meta: {
              name: '添加银行卡',
            },
            path: 'add',
            component: () => import('../views/member_center/bank-card/add.vue'),
          },
          {
            meta: {
              name: '',
            },
            path: 'detail/:id',
            component: () =>
              import('../views/member_center/bank-card/detail.vue'),
          },
        ],
      },
      {
        meta: {
          name: '下注记录',
        },
        path: 'bets_log',
        component: () => import('../views/member_center/bets_log.vue'),
      },
      {
        meta: {
          name: '充值记录',
        },
        path: 'deposit_log',
        component: () => import('../views/member_center/deposit_log.vue'),
      },
      {
        meta: {
          name: '资金明细',
        },
        path: 'money_log',
        component: () => import('../views/member_center/money_log.vue'),
      },
      {
        meta: {
          name: '额度转换查询',
        },
        path: 'transfer_check',
        component: () => import('../views/member_center/transfer_check.vue'),
      },
      {
        meta: {
          name: '额度转换查询',
        },
        path: 'transfer_money',
        component: () => import('../views/member_center/transfer_money.vue'),
      },
      {
        meta: {
          name: '邀请注册二维码',
        },
        path: 'invite',
        component: () => import('../views/member_center/invite.vue'),
      },
      {
        meta: {
          name: '消息中心',
        },
        path: 'notification/:id?',
        component: () => import('../views/member_center/notification.vue'),
      },
      {
        meta: {
          name: '关于我们',
        },
        path: 'about/:type?',
        component: () => import('../views/member_center/about.vue'),
      },
      // {
      //   meta: {
      //     name: 'APP 下载',
      //   },
      //   path: 'download',
      //   component: () => import('../views/member_center/download.vue'),
      // },
      {
        meta: {
          name: '设置',
        },
        path: 'setting',
        component: () => import('../views/member_center/setting/setting.vue'),
        children: [
          {
            meta: {
              name: '修改手机号码',
            },
            path: 'phone',
            component: () => import('../views/member_center/setting/phone.vue'),
          },
          {
            meta: {
              name: '修改登录密码',
            },
            path: 'password',
            component: () =>
              import('../views/member_center/setting/password.vue'),
          },
        ],
      },
      {
        meta: {
          name: '提现记录',
        },
        path: 'withdraw_log',
        component: () => import('../views/member_center/withdraw_log.vue'),
      },
    ],
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: () => import('../views/tutorial/tutorial.vue'),
    redirect: '/tutorial/syphon',
    children: [
      {
        meta: {
          title: 'Syphon 软件教程',
        },
        path: 'syphon',
        component: () => import('../views/tutorial/syphon/syphon.vue'),
      },
      {
        meta: {
          title: 'Outline 软件教程',
        },
        path: 'vpn',
        component: () => import('../views/tutorial/vpn/vpn.vue'),
      },
      {
        meta: {
          title: '注册教学',
        },
        path: 'register',
        component: () => import('../views/tutorial/register/register.vue'),
      },
      {
        meta: {
          title: 'FAQ',
        },
        path: 'faq/:type?',
        component: () => import('../views/tutorial/faq/faq.vue'),
      },
    ],
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes,
})

const routerHandler = async (to, from, next) => {
  // await store.restored
  // next()
  Vue.nextTick(() => {
    const pageTitle = _.get(to, 'meta.title') || ''
    document.title = `${pageTitle}${pageTitle ? ' - ' : ''}${defaultTitle}`
  })
}
router.afterEach(routerHandler)

export default router
