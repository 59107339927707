<template>
  <div v-show="is_wechat" class="mask">
    <img
      alt=""
      class="hint-img"
      data-aos="fade-up"
      data-aos-delay="200"
      src="../assets/images/browser-hint.jpeg"
    />
    <section data-aos="fade-up" data-aos-delay="300">
      <p>您现在使用的是微信<br />请使用浏览器打开 <br />體驗最佳瀏覽方式</p>
    </section>
    <img
      :src="$store.state.site.logoLogin"
      alt=""
      class="logo"
      data-aos="fade"
      data-aos-delay="400"
    />
  </div>
</template>

<style lang="scss" scoped>
.mask {
  background-color: #343445;
  background-image: url('../assets/images/login-bg-1.png');
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  display: flex;

  p {
    color: white;
    font-size: 22px;
    line-height: 1.5;
    padding: 1rem 1.5rem;
    /* background: rgba(0, 0, 0, .3); */
    border-radius: 15px;
    margin-top: -2rem;
  }

  .hint-img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  @media screen and (min-width: 767px) {
    .hint-img {
      width: 700px;
      opacity: 0.7 !important;
    }
  }

  .logo {
    width: sizem(100);
    display: block;
    margin: 0 auto;
  }

  .icon {
    width: 100px;
    height: auto;
  }

  svg {
    fill: #fff;
    width: 100px;
  }
}
</style>

<script>
import device_detector from '@/mixins/device_detector'

export default {
  name: 'WechatHint',
  mixins: [device_detector],
  data() {
    return {}
  }
}
</script>
