<template>
  <div id="app" ref="appRoot">
    <!-- <AstrictMask /> -->
    <WechatHint />
    <router-view />
  </div>
</template>

<style lang="scss">
@import url('./assets/font/noto-serif-600.css');
@import url('./assets/font/noto-sans-300.css');
@import url('./assets/font/noto-sans-400.css');
@import url('./assets/font/noto-sans-500.css');
@import url('./assets/font/noto-sans-900.css');
</style>

<script>
// import AstrictMask from '@/components/AstrictMask'
import WechatHint from '@/components/WechatHint'
import { getSiteName, isMobile } from '@/utils'
import _ from 'lodash'

export default {
  name: 'App',
  components: {
    // AstrictMask,
    WechatHint
  },
  methods: {
    checkWindowInnerWidth: function () {
      if (typeof window === 'undefined') return

      try {
        if (window.innerWidth < 768 || isMobile()) {
          this.$refs.appRoot.classList.add('md')
          this.$store.commit('setIsMobileLayout', true)
        } else {
          this.$refs.appRoot.classList.remove('md')
          this.$store.commit('setIsMobileLayout', false)
        }
      } catch {
      }
    }
  },
  mounted() {
    // this.$store.commit('setSite', getSiteName(_.get(this.$route, 'query.site')))
    this.checkWindowInnerWidth()

    window.onresize = () => {
      this.checkWindowInnerWidth()
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.$store.commit('setSite', getSiteName(_.get(route, 'query.agent')))
      }
    }
  }
}
</script>
