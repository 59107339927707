<template>
  <div v-if="!$store.state.isMobileLayout" class="slogan flex">
    <div class="slogan-item flex-c wrap">
      <img alt="" class="slogan-icon" src="@/assets/images/diamond.png" />
      <div class="slogan-title">合法</div>
      <div class="slogan-desc">持有合法网上赌牌</div>
    </div>
    <div class="slogan-item flex-c wrap">
      <img alt="" class="slogan-icon" src="@/assets/images/safe.png" />
      <div class="slogan-title">真实</div>
      <div class="slogan-desc">多家赌场视频，即时官网核对</div>
    </div>
    <div class="slogan-item flex-c wrap">
      <img alt="" class="slogan-icon" src="@/assets/images/pig.png" />
      <div class="slogan-title">信誉</div>
      <div class="slogan-desc">多年好口碑，出金稳定</div>
    </div>
    <div class="slogan-item flex-c wrap">
      <img alt="" class="slogan-icon" src="@/assets/images/stars.png" />
      <div class="slogan-title">多元</div>
      <div class="slogan-desc">全网最多百家乐直播台</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'slogan',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.slogan {
  width: 100%;
  height: size(315);
  position: relative;
  background-color: #18171d;

  .slogan-item {
    width: 25%;
    height: 100%;
    align-content: center;

    .slogan-icon {
      height: size(60);
      margin-bottom: size(30);
    }

    .slogan-title {
      width: 100%;
      color: white;
      font-size: size(28);
      margin-bottom: size(30);
    }

    .slogan-desc {
      width: 100%;
      color: #eee;
      font-size: size(18);
    }
  }
}
</style>
