<template>
  <div class="navigator flex-ac flex-jb">
    <router-link
      v-for="nav in navs"
      :key="nav.label"
      :to="nav.path"
      class="nav-item flex-c wrap"
    >
      <img :src="nav.icon" alt="" class="nav-icon" />
      <div class="nav-title" v-text="nav.label"></div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'navigator',
  data() {
    return {}
  },
  computed: {
    navs() {
      return [
        {
          label: '娱乐中心',
          show: true,
          path: '/',
          icon: require('@/assets/images/game-icon.png')
        },
        {
          label: '充值提现',
          show: false,
          path: '/',
          icon: require('@/assets/images/save-icon.png')
        },
        {
          label: '额度转换',
          show: false,
          path: '/',
          icon: require('@/assets/images/transfer-icon.png')
        },
        {
          label: 'APP下载',
          show: true,
          path: '/download_app',
          icon: require('@/assets/images/download-icon.png')
        },
        {
          label: '联络我们',
          show: true,
          path: '/contact_us',
          icon: require('@/assets/images/member_center/11_contact.png')
        },
        {
          label: '會員中心',
          show: true,
          path: '/member_center',
          icon: require('@/assets/images/user-icon.png')
        }
      ].filter(n => n.show)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.navigator {
  width: 100vw;
  height: size(100);
  position: relative;
  padding: 0 size(80);
}

.nav-item {
  cursor: pointer;
}

.nav-icon {
  width: size(50);
}

.nav-title {
  color: white;
  font-size: size(24);
  margin-left: size(10);
}

@include md {
  .navigator {
    width: 100vw;
    height: sizem(80);
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 5px;
    background: #171920;
    z-index: 10;
  }

  .nav-icon {
    width: sizem(60);
    height: sizem(60);
    margin-top: -1rem;
    margin-bottom: 0.5rem;
  }

  .nav-title {
    color: white;
    font-size: size-m(14);
    margin-left: sizem(0);
  }
}
</style>
