import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import VueCookies from 'vue-cookies'
import VueCompositionAPI from '@vue/composition-api'
import Qs from 'qs'
import 'ant-design-vue/dist/antd.css'
import '@/assets/style/global.scss'
import AOS from '@/lib/aos/src/js/aos'
import 'aos/dist/aos.css'

Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(VueCookies)
Vue.use(VueCompositionAPI)

Vue.prototype.Qs = Qs
Vue.prototype.window = window

Vue.directive('src', (el, binding) => {
  el.style.opacity = 0
  if (binding.value) {
    const img = new Image()
    img.src = binding.value
    img.onload = () => {
      el.src = binding.value
      el.style.opacity = 1
    }
    img.onerror = () => {
      el.src = ''
      el.style.opacity = 1
    }
  }
})

new Vue({
  router,
  store,
  created() {
    AOS.init()
  },
  render: h => h(App),
}).$mount('#app')
