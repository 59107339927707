import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'
import Qs from 'qs'
import { get, post } from '@/axios'
import _ from 'lodash'
import VuexPersistence from 'vuex-persist'
import dayjs from 'dayjs'
import { androidSyphonDownloadUrl, iosSyphonDownloadUrl, smsPeriod } from '@/const'
import { getLogo, getLogoLogin } from '@/utils'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    token: state.token,
    sms: state.sms
  })
})

export default new Vuex.Store({
  state: {
    userInfo: {
      agent_name: '',
      alipay_code: null,
      avatar: '',
      balance: null,
      branch_name: null,
      create_date: '',
      email: null,
      member_id: '',
      nick_name: '',
      pic_name: '',
      pic_url: '',
      sex: null,
      status: '',
      totalBalance: null,
      user_name: '',
      // 以下来自 gamecenter_getGameList.do
      agent_domain: '',
      city: null,
      domain_abb: '',
      domain_prefix: '',
      invite_code: null,
      member_type: '',
      phone: '',
      show_domain: '',
      token: ''
    },
    username: '',
    memberid: '',
    token: '',
    ip_json: {},
    showResponseModal: false,
    showTransferModal: false,
    games: [],
    msgTitle: '',
    isMobileLayout: false,
    bankCards: [],
    areaCodes: [],
    sms: {
      createTime: null,
      finishTime: null,
      remainSecond: smsPeriod,
      timer: null
    },
    site: {
      name: '',
      logo: '',
      logoLogin: ''
    },
    syphon: {
      ios: iosSyphonDownloadUrl,
      android: androidSyphonDownloadUrl
    }
  },
  getters: {
    isLogin: state => _.size(state.userInfo.member_id) > 0
  },
  mutations: {
    setUserInfo(state, userInfo) {
      Object.keys(userInfo).forEach(key => {
        if (userInfo[key] === null) {
          delete userInfo[key]
        }
      })
      state.userInfo = {
        ...state.userInfo,
        ...userInfo
      }
    },
    setPower_register(state, value) {
      state.userInfo.power_register = value
    },
    setPower_customer(state, value) {
      state.userInfo.power_customer = value
    },
    setPower_recharge(state, value) {
      state.userInfo.power_recharge = value
    },
    setPower_withdrawal(state, value) {
      state.userInfo.power_withdrawal = value
    },
    setPower_switch(state, value) {
      state.userInfo.power_switch = value
    },
    setGameMoney(state, value) {
      state.gamemoney = value
    },
    setUsername(state, value) {
      state.username = value
    },
    setMemberId(state, value) {
      state.memberid = value
    },
    setIpJson(state, value) {
      state.ip_json = value
    },
    setShowResponse(state, value) {
      state.showResponseModal = value
    },
    setTransferModal(state, value) {
      state.showTransferModal = value
    },
    setGames(state, value) {
      state.games = value
    },
    setMsgTitle(state, value) {
      state.msgTitle = value
    },
    setIsMobileLayout(state, value) {
      state.isMobileLayout = value
    },
    setAreaCodes(state, value) {
      state.areaCodes = value
    },
    setBankCards(state, value) {
      state.bankCards = value
    },
    setToken(state, value) {
      state.token = value
    },
    setSmsCreateTime(state) {
      state.sms.createTime = dayjs().valueOf()
      state.sms.finishTime = dayjs().add(smsPeriod, 'second').valueOf() + 500
    },
    setSmsRemainSecond(state, value) {
      state.sms.remainSecond = value
    },
    setSmsTimer(state, value) {
      state.sms.timer = value
    },
    resetSms(state) {
      if (state.sms.timer) {
        clearInterval(state.sms.timer)
      }
      state.sms = {
        createTime: null,
        finishTime: null,
        remainSecond: smsPeriod,
        timer: null
      }
    },
    setSite(state, name) {
      const logo = getLogo(name)
      const logoLogin = getLogoLogin(name)

      state.site = {
        name,
        logo,
        logoLogin
      }
    }
  },
  actions: {
    async logOut({ commit }) {
      //remove cookie
      window.$cookies.remove('username')
      window.$cookies.remove('memberid')
      commit('setUsername', '')
      commit('setMemberId', '')
      commit('setToken', '')
      localStorage.removeItem('token')
      await router.push('/login')
    },
    logIn({ commit }, username, memberid) {
      commit('setUsername', username)
      commit('setMemberId', memberid)
      commit('setShowResponse', true)
    },
    async getUserInfo({ commit, dispatch }) {
      try {
        const { code, data } = await post(
          'member_mobile_getUserInfo.do',
          Qs.stringify({ mapStr: {} })
        )

        if (code === 'success') {
          const userInfo = JSON.parse(data)

          commit('setUserInfo', {
            ...userInfo,
            avatar: `${
              process.env.NODE_ENV === 'production'
                ? process.env.VUE_APP_PRD_URL
                : process.env.VUE_APP_DEV_URL
            }${userInfo.pic_url}`
          })
          const gameBlance = JSON.parse(userInfo.gameBlance)
          commit('setGameMoney', gameBlance)

          if (!userInfo.token) {
            await dispatch('getGameList')
          }
          const funcObj = JSON.parse(userInfo.function)
          commit('setPower_switch', funcObj.power_switch)
          commit('setPower_withdrawal', funcObj.power_withdrawal)
          commit('setPower_recharge', funcObj.power_recharge)
          commit('setPower_customer', funcObj.power_customer)
          commit('setPower_register', funcObj.power_register)

        }
      } catch (e){
        console.error(e)
      }
    },
    async getGameList({ commit, dispatch }) {
      try {
        const { code, data } = await get('gamecenter_getGameList.do')
        if (code === 'fail') {
          dispatch('logOut')
        } else {
          const { gameclassList, user } = JSON.parse(data)
          const userObj = JSON.parse(user)
          const { member_id, token } = userObj
          commit('setGames', gameclassList)
          commit('setMemberId', member_id)
          commit('setUserInfo', userObj)
          if (token) {
            localStorage.setItem('token', token)
            commit('setToken', token)
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    async getBankCards({ commit }) {
      try {
        const { code, data, info } = await post(
          'member_card_getBankCardList.do'
        )
        if (code === 'fail') {
          this.$message.error(info, 2)
        } else {
          const { cardList } = JSON.parse(data)
          commit('setBankCards', cardList)
        }
      } catch {
      }
    },
    async getAreaCodes({ commit }) {
      try {
        const { mapList } = await post('common_getAreaPixfix.do')
        commit('setAreaCodes', mapList)
      } catch {
      }
    }
  },
  modules: {},
  plugins: [vuexLocal.plugin]
})
