<template>
  <div class="header flex-ac flex-jb">
    <img
      :src="$store.state.site.logo"
      alt=""
      class="logo"
      data-aos="fade"
      data-aos-delay="200"
    />
    <div
      v-if="$store.state.isMobileLayout && show_translate"
      class="flags flex-jc flex-ac"
    >
      <a
        class="gflag flag nturl"
        href="#"
        onclick="doGTranslate('zh-CN|zh-CN');return false;"
        title="Chinese (Simplified)"
      ><img alt="" src="../assets/images/flag_cn.png"
      /></a>
      <a
        class="gflag flag nturl"
        href="#"
        onclick="doGTranslate('zh-CN|en');return false;"
        title="English"
      ><img alt="" src="../assets/images/flag_en.png"
      /></a>
      <a
        class="gflag flag nturl"
        href="#"
        onclick="doGTranslate('zh-CN|vi');return false;"
        title="Vietnamese"
      ><img alt="" src="../assets/images/flag_vn.png"
      /></a>
      <a
        class="gflag flag nturl"
        href="#"
        onclick="doGTranslate('zh-CN|km');return false;"
        title="Khmer"
      ><img alt="" src="../assets/images/flag_kh.png"
      /></a>
    </div>
    <div v-if="!$store.state.isMobileLayout" class="links flex-ac">
      <div v-if="show_translate" class="flags flex-js flex-ac">
        <a
          class="gflag flag nturl"
          href="#"
          onclick="doGTranslate('zh-CN|zh-CN');return false;"
          title="Chinese (Simplified)"
        ><img alt="" src="../assets/images/flag_cn.png"
        /></a>
        <a
          class="gflag flag nturl"
          href="#"
          onclick="doGTranslate('zh-CN|en');return false;"
          title="English"
        ><img alt="" src="../assets/images/flag_en.png"
        /></a>
        <a
          class="gflag flag nturl"
          href="#"
          onclick="doGTranslate('zh-CN|vi');return false;"
          title="Vietnamese"
        ><img alt="" src="../assets/images/flag_vn.png"
        /></a>
        <a
          class="gflag flag nturl"
          href="#"
          onclick="doGTranslate('zh-CN|km');return false;"
          title="Khmer"
        ><img alt="" src="../assets/images/flag_kh.png"
        /></a>
      </div>
      <div
        class="link"
        data-aos="fade-left"
        data-aos-delay="200"
        @click="is_show_about_modal = true"
      >
        关于我们
      </div>
      <div
        class="link"
        data-aos="fade-left"
        data-aos-delay="300"
        @click="is_show_private_modal = true"
      >
        常见问题
      </div>
      <div
        class="link"
        data-aos="fade-left"
        data-aos-delay="400"
        @click="is_show_contract_modal = true"
      >
        负责任的赌博
      </div>
      <div
        class="link"
        data-aos="fade-left"
        data-aos-delay="500"
        @click="is_show_contact_modal = true"
      >
        服务条款
      </div>
    </div>
    <a-modal v-model="is_show_about_modal" @ok="is_show_about_modal = false">
      <template slot="title">
        <div class="modal-title">关于我们</div>
      </template>
      <div class="modal-content">
        歡迎蒞臨本网站！本网站提供多元化網上娛樂服務，以現場遊戲、電子遊戲及體育博彩榮的互動娛樂平台。<br /><br />

        本网站秉承「以客為先」的理念，本网站不但一直提升服務質素，更全面優化系統，力求保障每項交易也是絕對安全保密。我們保證，本网站的所有遊戲均在最公平公正的原則下進行，讓玩家能夠在一個平穩可靠的平台上，盡情體會精彩刺激的遊戲樂趣。<br /><br />

        近年，我們成功拓展多元化娛樂平台，更先後推出現場遊戲、體育博彩、電子遊戲三大精彩類別，讓大家有更多的娛樂選擇：<br /><br />

        <h3>現場遊戲</h3>

        本网站嶄新開發「互動多合一」，讓玩家與即時交流，時刻勝券在握。除了極受歡迎的經典百家樂，本平台更嶄新推出星級百家樂、21點、雀九等精彩玩意，全天候歡迎玩家投入其中！<br />

        <h3>體育博彩</h3>

        本网站即時提供全球最熱門體育賽事的賠率及走勢，包括歐洲四大足球聯賽、美國NBA及網球四大滿貫等國際賽事。您更可以挑選多種投注方式，例如讓分&大小、半場/全場、獨贏等，再根據最新最詳盡的實時數據，掌握贏錢先機！<br />

        <h3>電子遊戲</h3>

        特設多個遊戲專區,玩家即可以隨意選擇各式老虎機、桌面遊戲及對戰玩意，考驗運氣，挑戰技術，感受非一般的娛樂快感，全情投入電子遊戲世界！<br />

        憑藉嶄新的科技與創意，以及客戶的信任與支持，本网站已連續多年寫下佳績。往後，我們將會繼續堅持「求變」精神，努力不懈地尋求突破，為玩家研發更多優質遊戲，邁向世界！
      </div>
      <template slot="footer" class="modal-footer">
        <a-button
          key="submit"
          type="primary"
          @click="is_show_about_modal = false"
        >
          确认
        </a-button>
      </template>
    </a-modal>

    <a-modal
      v-model="is_show_private_modal"
      @ok="is_show_private_modal = false"
    >
      <template slot="title">
        <div class="modal-title">常见问题</div>
      </template>
      <div class="modal-content">
        Q1: 如何加入本网站？<br />
        A1: 您可以直接点选
        "立即加入"，确实填写数据后，可立即登记成为本网站会员。<br /><br />

        Q2: 我可以直接在网络上存款吗？<br />
        A2: 可以，本网站提供多种在线存款选择，详情请参照
        "存款帮助"或联系客服。<br /><br />
        Q3: 我在哪里可以找到游戏规则？<br />
        A3:
        在未登入前，您可以在游戏的最外层看到"游戏规则"选项，清楚告诉您游戏的玩法、规则及派彩方式。
        在游戏窗口中,也有"规则"选项，让您在享受游戏乐趣的同时，可以弹跳窗口随时提醒您游戏规则。<br /><br />

        Q4: 妳们的游戏会用多少副牌？<br />
        A4: 在百家乐我们会用8副牌，其他游戏则会根据其性质有所调整。<br /><br />

        Q5: 您们何时会洗牌?<br />
        A5:
        所有纸牌游戏，当红的洗牌记号出现或游戏因线路问题中断时便会进行重新洗牌。<br /><br />

        Q6: 我的注码的限制是多少？<br />
        A6: 从最低注单10元以上即可投注，
        您的注码会根据您的存款有所不同，以及您挑选的游戏不同而有所区别.<br /><br />

        Q7：如果忘记密码怎么办？<br />
        A7：妳可点击首页忘记密码功能，填写妳当初留下的邮箱，即可取回妳当
        初设定的密码。当妳无法收取邮件时，妳也可以联系24小时在线客服人员谘询协助取回妳的账号密码。<br /><br />

        如有任何不解可联系本网站24小时在线客服！我们将在第一时间为您服务。
      </div>
      <template slot="footer" class="modal-footer">
        <a-button
          key="submit"
          type="primary"
          @click="is_show_private_modal = false"
        >
          确认
        </a-button>
      </template>
    </a-modal>

    <a-modal
      v-model="is_show_contract_modal"
      @ok="is_show_contract_modal = false"
    >
      <template slot="title">
        <div class="modal-title">负责任的赌博</div>
      </template>
      <div class="modal-content">
        对于绝大多数人来说，赌博是一种无害的消遣。对于大多数人来说，赌博（无论是赛马、彩票还是在赌场）是一种休闲和娱乐形式。他们参加比赛，或投注体育赛事，知道他们能花多少钱。他们花在赌博上的时间也不会超出他们的承受能力。<br /><br />

        当然，他们不会因为赌博而忽视家人和家属，赌博也不会影响他们的工作。他们也不会因为赌博而陷入严重的经济困难，然后希望更多的赌博能解决他们的财务问题。<br /><br />

        然而，对于少数人来说，赌博确实成为他们生活中的一个问题。特别是，他们怀疑，如果他们停止赌博，或者至少减少和控制他们的赌博，会更好。对于其中一些人来说，赌博可能会成瘾。<br /><br />

        <h3>经常问的问题</h3>
        我家境不错，受过良好教育，成熟稳重，这是否意味着我可以控制自己的赌博？<br /><br />

        不必要！无论年龄、种族、宗教、教育程度、经济状况和道德品质如何，任何人都可能成为问题赌徒。问题赌博就像一种可以影响任何人的疾病。有些人似乎有赌博问题，就像有些人有酗酒或其他药物成瘾的倾向一样。<br /><br />

        在这些情况下，他们知道赌博给他们带来了严重的麻烦，他们非常努力地控制它。不幸的是，他们发现，无论他们怎么努力，都无法靠自己停止或减少赌博。<br /><br />

        有些人为了克服困难，需要其他对赌博问题有特殊了解的人的帮助。<br /><br />

        请记住，18
        岁以下的儿童参与赌博是违法的。例如，在赌场，确保您始终知道您的孩子在做什么。负责任的父母永远不会让孩子无人看管。大多数赌场提供托儿所和其他受监管的儿童保育设施。使用它们，但不要因为有人照顾他们而长时间离开您的孩子。他们仍然是你的责任。非常重要的是，不要让您的孩子代您下注，或允许他们购买彩票。<br /><br />

        1) 承认自己有赌博问题，这不是很可耻吗？<br />
        不！问题赌博就像一种疾病：如果你感染了它不是你的错，但你有责任对它做一些明智的事情。可耻的是继续对自己和所爱的人造成严重伤害，而对此却无所作为。显然，人们最好能避免赌博变得无法控制的情况。<br /><br />

        要做到这一点，人们需要了解可以为他们提供早期预警的危险信号。然后他们就可以在不寻求进一步帮助的情况下采取适当的行动。例如，这样做的一种方法是联系赌场经理，并要求禁止您赌博。<br /><br />

        如果您遵守这些规则，就可以避免赌博问题<br /><br />

        1. 事先决定您愿意花多少钱。坚持你的极限。<br />
        2. 不要试图弥补你的损失。它们可能只会增加。<br />
        3. 注意你玩的频率和时间。<br />
        4. 当你赢了，偶尔休息一下。想想停止的明智时间<br />
        5. 请记住，赌博是一种娱乐。这不是一种快速致富或还清债务的方法。<br />
        不要忘记赌博是一种机会游戏。没有任何系统或公式可以保证成功。如果您在赌博问题上需要帮助，为您自己、朋友或您关心的人
      </div>
      <template slot="footer" class="modal-footer">
        <a-button
          key="submit"
          type="primary"
          @click="is_show_contract_modal = false"
        >
          确认
        </a-button>
      </template>
    </a-modal>

    <a-modal
      v-model="is_show_contact_modal"
      @ok="is_show_contact_modal = false"
    >
      <template slot="title">
        <div class="modal-title">服务条款</div>
      </template>
      <div class="modal-content">
        <h3>同意接受条款</h3>

        以下条款适用于用户使用本网站、“我们”及“我们的”，视具体情况而定
        所提供的网上多元资讯分享.请仔细阅读本条款，并确认您完全理解其内容，本服务条款有可能不时更新而不另行通知阁下.如果对于这一条款所产生的权利和义务有任何疑问，请向您所属法律管辖区域内的法律顾问寻求帮助.<br /><br />

        <h3>修改</h3>

        本网站网上娱乐保留不时修订、更新和修改本条规(或其任何部分)的绝对权利.上述任何修订、更新或修改将在网站上公布.经修订、更新或修改的本条款会在本网站上公布时生效.用户在之后继续使用、联接时，将视为同意并接受所公布经已修改的或更新的规则.用户确认并同意自行负责查阅上述修订、更新或修改.我们可自主决定将上述修订、更新和修改通知用户.上述修订、更新和修改可由我们自主决定不时通知用户，但我们并无任何义务向用户通知任何更新和修改.<br /><br />

        <h3>服务说明</h3>

        本网站为用户提供丰富的线上资源.用户必须明白及同意本服务可能包括本网站发出的广告推介，而这些通讯是本网站的一部份，不能选择停止接收.<br /><br />

        用户须同意本网站及一切有关服务，只供作个人而非商业用途.用户不得把本服务的任何部分，复制、保留副本、抄袭、出售、买卖、转卖或用作任何商业用途.阁下同意，本服务条款另有明确规定的除外，本协议将不存在任何第三方的权益人.<br /><br />

        <h3>私隐</h3>

        由网上娱乐保存与用户有关“登记资料”及其他相关资料均受到个人资料条例和本网规管.您所提供的资料会储存在安全的伺服器上.然而，我们保留权利在法例规定或容许时披露辨认个人身份的资料.<br /><br />

        <h3>账号、密码及安全</h3>

        您完成注册开户后，将收到一个密码及账号.维持密码及账号的机密安全，是您的责任.利用该密码及账号所进行的一切行动，全由您负责.如密码或账号未获授权遭到使用，或者发生任何安全问题时，请向我们联络，同时，请确保每次登入完毕后结束账号，否则任何随之带来的损失，我们将不会负责.<br /><br />

        <h3>使用者行为</h3>

        使用本网站时，您同意：<br /><br />

        不会中断或干扰网站的安全性，或以其他方式滥用本网站或本网站上提供的任何服务、系统资源、账号、伺服器、与本网站连线或可透过本网站存取的网路或其关联或连结网站；<br />
        不会中断或干扰任何其他使用者使用本网站或其关联或连结网站；<br />
        不会在本网站上散播任何病毒或其他有害、破坏性或毁坏性档案；<br />
        未经授权，不会使用或尝试使用其他人的账号、服务或系统，不会在本网站上建立或使用虚假的身份.<br /><br />
        <h3>终止</h3>

        本网站有权在基于自行考虑下，因任何理由终止您的密码及账号(或其任何部分)或本服务之使用.我们无论有否通知您，都可以依其自行之考虑随时终止服务或其任何部分.
      </div>
      <template slot="footer" class="modal-footer">
        <a-button
          key="submit"
          type="primary"
          @click="is_show_contact_modal = false"
        >
          确认
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import translate from '@/mixins/translate'

export default {
  name: 'page-header',
  mixins: [translate],
  data() {
    return {
      is_show_about_modal: false,
      is_show_private_modal: false,
      is_show_contract_modal: false,
      is_show_contact_modal: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.header {
  width: 100vw;
  height: size(200);
  position: relative;
  top: 0;
  left: 0;
  padding: 0 size(60);

  > img {
    height: inherit;
    width: auto;
    padding: 20px;
  }
}

.logo {
  width: size(300);
}

// .links {
// }

.flags {
  /* width: 100%; */
  /* margin-bottom: size(30); */

  .flag {
    width: size(30);
    margin-right: size(50);

    img {
      width: 100%;
    }
  }
}

.link {
  color: white;
  margin-left: size(40);
  font-size: size(24);
  cursor: pointer;

  &:hover {
    color: #f5961b;
  }
}

.modal-title {
  font-size: 1rem;
  text-align: center;
}

.modal-content {
  font-size: 0.7rem;
  color: black;
  line-height: 1.8;
  height: 15rem;
  overflow-y: scroll;

  h3 {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
}

.modal-footer {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.ant-checkbox-wrapper {
  width: 100%;
  margin-bottom: 1rem;
}

@include md {
  .header {
    width: 100vw;
    height: sizem(120);
    position: relative;
    top: 0;
    left: 0;
    padding: 0;
    flex-wrap: wrap;
  }

  .logo {
    width: sizem(140);
    display: block;
    margin: 0 auto;
  }

  .flags {
    width: 100%;
    margin-top: 15px;
    /* margin-bottom: sizem(40); */

    .flag {
      width: sizem(35);
      margin: 0 sizem(20);
    }
  }

  .modal-content {
    height: 10rem;
  }

  // .links {
  // }

  .link {
    color: white;
    margin-left: sizem(40);
    font-size: size-m(24);
  }
}
</style>
